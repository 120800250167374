<template>
  <v-navigation-drawer
    app
    clipped
    v-model="drawer"
    absolute
    height="100%"
    color="#000000"
    :right="true"
    :style="'position: fixed; z-index: 10;'"
  >
    <v-divider />
    <v-list nav dense>
      <DrawerItem
        v-for="item in items"
        :key="item.title"
        :icon="item.icon"
        :title="item.title"
        :linkTo="item.link"
      />
    </v-list>
    <template v-slot:append>
      <div style="display: flex;" class="align-center justify-center ma-0">
        <v-btn
          dark
          icon
          class="ml-2"
          x-small
          target="_blank"
          href="https://api.whatsapp.com/send?phone=524441176494&text=Hola,%20me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n."
        >
          <v-icon color="white">mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn
          dark
          class="ml-2"
          icon
          x-small
          target="_blank"
          href="https://www.linkedin.com/in/jose-bernardo-bernal-cabrera-773a24116"
        >
          <v-icon color="white">mdi-linkedin</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from "vuex";
import { Items } from "./Items";
export default {
  name: "Drawer",
  data: function() {
    return {
      drawer: false
    };
  },
  components: {
    DrawerItem: () => import("@/components/Drawer/DrawerItem.vue")
  },
  computed: {
    ...mapGetters({
      state: "drawer"
    }),
    items: function() {
      return Object.assign([], Items);
    }
  },
  watch: {
    state: function(val) {
      this.drawer = val;
    }
  }
};
</script>
