export const Items = [
  {
    icon: "mdi-home",
    title: "Inicio",
    link: "#Inicio"
  },
  {
    icon: "mdi-account ",
    title: "Sobre Mí",
    link: "#SobreMi"
  },
  {
    icon: "mdi-briefcase ",
    title: "Portafolio",
    link: "#Portafolio"
  },
  {
    icon: "mdi-av-timer",
    title: "Experiencia",
    link: "#TimeLine"
  },
  {
    icon: "mdi-contacts ",
    title: "Contacto",
    link: "#Contacto"
  }
];
